<template>
  <v-container fluid>
    <div>
      <h1 class="text-h4">{{ $lang.header.library }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row v-if="err || success" dense no-gutters class="px-0">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
      </v-row>
      <v-row wrap no-gutters class="pb-4">
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
          sm="12"
          md="8"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="pb-6 fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="py-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1"
                    >
                      <div class="d-inline-flex max-width">
                        <v-text-field
                          v-model="library.name"
                          outlined
                          dense
                          :label="$lang.labels.name"
                          required
                          class="required-asterisk"
                          :rules="[v => !!v || 'Required!']"
                          :persistent-hint="isEdit"
                          :hint="formatHint"
                          :readonly="!userCanEdit"
                          data-cy="library-name"
                        ></v-text-field>
                        <v-btn
                          icon
                          color="primary"
                          class="ml-2"
                          style="margin-top: 2px"
                          @click="copyName()"
                        >
                          <v-icon>mdi-clipboard-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-sm-1 text-right pb-2">
                      <v-btn
                        class="button-default-width"
                        color="primary"
                        :disabled="!userCanEdit"
                        @click="changeStatus()"
                      >
                        {{ library.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters :class="isEdit ? 'pb-0' : 'pb-2'">
                    <v-col
                      cols="12"
                      sm="9"
                    >
                      <v-select
                        v-model="library.type"
                        class="pr-1 required-asterisk"
                        :items="typesFormatted"
                        :label="$lang.labels.type"
                        outlined
                        dense
                        :rules="[v => !!v || 'Required!']"
                      ></v-select>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-sm-1 text-right pb-2">
                      <v-text-field
                        ref="fileTextField"
                        v-model="file"
                        prepend-icon="mdi-paperclip"
                        outlined
                        solo
                        dense
                        readonly
                        hide-details
                        :label="$lang.actions.clickToImport"
                        class="min-w"
                        @click="startInput('fileInput')"
                        @click:prepend="startInput('fileInput')"
                      />
                      <input
                        ref="fileInput"
                        style="display: none;"
                        type="file"
                        accept=".js,.jar"
                        @change="onFileChange($event)"
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      class="d-inline-flex"
                    >
                      <v-checkbox v-model="library.isGdprRelevant" :label="$lang.labels.gdpr"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="err || success" dense no-gutters class="px-0">
                    <v-col v-if="err" cols="12">
                      <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
                    </v-col>
                    <v-col v-if="success" cols="12">
                      <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          color="secondary"
                          class="button-default-width"
                          :to="{ name: 'libraries', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="library.name"
                          :title="$lang.actions.delete"
                          type="library"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'library-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteLibraryFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'libraries' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <div class="d-inline-flex">
                        <v-btn
                          :disabled="!library.id || !fileData || lock || !userCanEdit"
                          color="accent"
                          :loading="loading"
                          class="button-default-width ml-2 color-black"
                          @click="uploadLibrary()"
                        >
                          {{ $lang.actions.upload }}
                        </v-btn>
                        <v-btn
                          :disabled="!valid || lock || !userCanEdit"
                          color="success"
                          :loading="loading"
                          class="button-default-width ml-2"
                          data-cy="library-submit"
                          @click="submit()"
                        >
                          {{ $lang.actions.submit }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreLibrary($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Table from '@/components/ui/tables/HistoryDatatable'
import {
  disableLibraryUsingGET as disableLibrary,
  enableLibraryUsingGET as enableLibrary,
  getLibraryByIdUsingGET as getLibrary,
  updateLibraryUsingPUT as updateLibrary,
  createLibraryUsingPOST as createLibrary,
  getRolesUsingGET as getRoles,
  executeUsingGET as executeLibrary,
  getHistoriesUsingGET as getHistories,
  deleteLibraryUsingDELETE as deleteLibrary,
  hubUploadModuleUsingGET as uploadModule,
  hubModuleUpdateUsingGET as downloadModule
} from '@/utils/api'
import auth from '@/auth'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import copy from 'copy-to-clipboard'

export default {
  components: {
    ActionButtonWithConfirmation,
    Table,
    UserRolesSelect
  },
  data() {
    return {
      tab: 0,
      err: '',
      success: '',
      valid: false,
      isEdit: false,
      loading: true,
      lock: false,
      library: {
        createdOn: '',
        id: '',
        modifiedOn: '',
        name: '',
        status: '',
        type: 'JS',
        roles: [],
        isGdprRelevant: false
      },
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      types: ['JS', 'GROOVY'],
      file: null,
      fileData: null
    }
  },
  computed: {
    typesFormatted () {
      return this.types.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.library.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.library.modifiedOn)}, ID: ${this.library.id}`
    }
  },
  watch: {},
  created() {
    this.isEdit = this.$route.name === 'libraryEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'LIBRARY_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true

          getLibrary({ id: this.$route.params.id })
            .then((res) => {
              this.library = res.data.data

              this.editRolesIds = this.library.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.library.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.library.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              this.loading = false
            })
            .catch((err) => {
              this.err = err

              this.loading = false
            })
        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            this.library = this.$route.params.copy
          }
          this.loading = false
        }
      })
  },
  methods: {
    copyName() {
      if (this.library.name) {
        copy(this.library.name)
        this.success = this.$lang.success.copiedClipboard
        setTimeout(() => this.success = '', 5000)
      } else {
        this.err = this.$lang.errors.nothingToCopy
        setTimeout(() => this.err = '', 5000)
      }
    },
    startInput(name) {
      this.$refs[name].click()
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) return
      this.fileData = files[0]
      this.file = `${this.$lang.labels.fileLoaded}: ${this.fileData.name}`
    },
    async uploadLibrary() {
      this.err = ''

      if (!this.fileData) return

      this.loading = true
      this.lock = true

      const data = new FormData()

      data.append('file', this.fileData)

      fetch(`/api/library/${this.library.id}/upload`, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'x-auth-token': auth.getToken()
        },
        body: data
      }).then((response) => {
        return response.json()
      }).then((res) => {
        if (res && res.status !== 'SUCCESS') {
          this.err = `${this.$lang.errors.libraryUpload}: ${res.statusText}`
          setTimeout(() => this.err = '', 5000)
          this.loading = false
          this.lock = false

          return
        }

        this.loading = false
        this.lock = false

        this.file = ''
        this.fileData = ''

        this.success = this.$lang.success.libraryUploaded
        setTimeout(() => this.success = '', 5000)

      }).catch((err) => {
        this.err = err
        this.loading = false
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      })
    },
    deleteLibraryFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteLibrary({ id: this.library.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.err = err
        })
    },
    fetchHistory(options) {
      if (!this.library.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.library.id
      obj.resourceType = 'PLUGIN'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.err = err
        })
    },
    restoreLibrary(data) {
      this.library = data

      if (this.library && this.library.roles) {
        this.editRolesIds = this.library.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.library.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.library.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async submit() {
      this.err = ''

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {
        this.err = this.$lang.errors.editRoleCreate
        setTimeout(() => this.err = '', 5000)
      }

      this.lock = true
      this.loading = true

      let res = null

      const { id } = this.library

      if (!this.isEdit) {
        delete this.library.id
        this.library.status = 'ACTIVE'
      }
      delete this.library.createdOn
      delete this.library.modifiedOn

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.library.roles = tempRoles

      try {

        res = this.isEdit ? await updateLibrary({ id, request: this.library }) : await createLibrary({ request: this.library })

        if (res && res.status !== 200) {
          this.err = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.libraryUpdate : this.$lang.errors.libraryCreate)
          setTimeout(() => this.err = '', 5000)
          this.lock = false
          this.loading = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.libraryUpdated : this.$lang.success.libraryCreated

        this.library = res.data.data

        if (!this.isEdit) {
          this.isEdit = true
          this.$router.push({
            name: 'libraryEdit',
            params: {
              id: res.data.data.id
            }
          })
        }

        if (this.fileData) {
          await this.uploadLibrary()
        } else {
          this.lock = false
          this.loading = false
        }

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async changeStatus () {
      this.lock = true
      try {
        const res = this.library.status === 'ACTIVE' ? await disableLibrary({ id: this.library.id }) : await enableLibrary({ id: this.library.id })

        if (res.status !== 200) {
          this.err = this.$lang.errors.libraryStatusUpdate
          setTimeout(() => this.err = '', 5000)
          this.lock = false
        }
        this.success = this.library.status !== 'ACTIVE' ? this.$lang.success.libraryActivated : this.$lang.success.libraryDeactivated

        this.library.status = res.data.data.status

        this.lock = false

        setTimeout(() => this.success = '', 5000)

      } catch (err) {
        this.err = err
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
